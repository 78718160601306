import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { PageProps } from "gatsby";
import Layout from "../../../components/Layout";

import { MetaData } from "../../../components/common/meta";
import ReleaseZeroOne from "./ReleaseZeroOne";
import bricks from "../../../images/bricks.svg";

import Arrow from "../../../images/arrow-right.inline.svg";
import lines from "../../../images/brandLines.svg";
import reports from "../../../images/research/r.svg";

const documentation = ({ location }: PageProps) => {
  return (
    <Layout>
      <MetaData title="Docs | Firedancer" location={location} type="website" />

      <Box
        component="span"
        sx={{
          position: "absolute",
          left: "65px",
          top: "200px",
          height: "calc(100% - 415px)",
          width: 0,
          borderLeft: "1px solid #4B608D",
          zIndex: 2,
          display: { xs: "none", xl: "block" },
          "&:before": {
            content: '""',
            position: "absolute",
            top: "-5px",
            left: "-8px",
            width: "15px",
            height: "5px",
            backgroundColor: "#1CE7C2",
          },
        }}
      />
      <Box
        component="img"
        src={reports}
        sx={{
          position: "absolute",
          right: 0,
          top: 0,
          zIndex: -1,
          pointerEvents: "none",
        }}
      />
      <Box component="div" sx={{ position: "relative" }}>
        <Box
          component="img"
          src={bricks}
          sx={{
            position: "absolute",
            left: "-140px",
            top: "-360px",
            zIndex: -1,
            pointerEvents: "none",
          }}
        />
        <Box
          component="div"
          sx={{
            maxWidth: 1082,
            mx: "auto",
            mb: 12,
          }}
        >
          <Box
            component="div"
            sx={{
              mt: { xs: 12, md: 20 },
              mb: { xs: 6, md: 12 },
            }}
          >
            <Typography sx={{ mb: 1.6 }} variant="h1">
              Documentation
            </Typography>
            <Typography sx={{ maxWidth: 440, pl: 2 }}>
              Guides, references, releases
            </Typography>

            <Button
              href="https://firedancer-io.github.io/firedancer/"
              component="a"
              target="_blank"
              variant="outlined"
              endIcon={<Arrow />}
              sx={{ mt: 2, px: 4, fontSize: 17, backgroundColor: "#091A3D" }}
            >
              firedancer docs
            </Button>
          </Box>


        </Box>
      </Box>

      <ReleaseZeroOne sx={{ mb: 12 }} />

      <Box
        component="img"
        src={lines}
        sx={{
          position: "absolute",
          right: "0",
          bottom: "0",
          zIndex: -1,
          pointerEvents: "none",
        }}
      />


    </Layout>
  );
};

export default documentation;
