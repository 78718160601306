import React from "react";
import { Box, Typography, Button } from "@mui/material";

import Arrow from "../../../images/arrow-right.inline.svg";
import sideBrand2 from "../../../images/pitLine2.svg";
import Github from "./../../../images/connect/github.inline.svg";
import article from "../../../images/article.svg";
import connectLines from "../../../images/connect/lines.svg";




const ReleaseZeroOne = (props: { sx: any }) => {

  return (
    <Box
      component="div" sx={{ ...props.sx }}>

      <Box
        component="img"
        src={connectLines}
        sx={{
          position: "absolute",
          top: "800px",
          left: -200,
          rotate: "155deg",
          transform: "scale(1.6)",
          opacity: 0.25,
          zIndex: -1,
          pointerEvents: "none",
        }}
      />
      <Box
        component="img"
        src={connectLines}
        sx={{
          position: "absolute",
          top: "1500px",
          left: 0,
          rotate: "200deg",
          transform: "scale(1.6)",
          opacity: 0.25,
          zIndex: -1,
          pointerEvents: "none",
        }}
      />
      <Box
        component="img"
        src={connectLines}
        sx={{
          position: "absolute",

          top: "1900px",
          right: -600,
          rotate: "180deg",
          transform: "scale(1.9)",
          opacity: 0.25,
          zIndex: -1,
          pointerEvents: "none",
        }}
      />


      <Box
        component="div"
        sx={{
          position: "relative",
          pt: 20,
          display: { xs: "none", xl: "block" },
          "&:before": {
            content: '""',
            position: "absolute",
            width: "calc(50% - 220px)",
            left: 50,
            top: 100,
            height: 0,
            borderTop: "1px solid #4B608D",
            display: { xs: "none", xl: "block" },
          },
          "&:after": {
            content: `url(${sideBrand2})`,
            position: "absolute",
            left: "calc(50% - 400px)",
            top: 98,
            display: { xs: "none", xl: "block" },
            zIndex: -1,
          },
        }}
      />
      <Box
        component="div"
        sx={{
          px: 2,
          textAlign: "center",
          mb: 2,
        }}
      >
        <Typography variant="h1" sx={{ mb: 1.6 }}>
          <Box component="span" sx={{ fontSize: 32 }}>
            release{' '}
          </Box>
          v0.1
          <Box component="span" sx={{ mb: 1.6, fontSize: 28 }}>{' '}(
            <Box component="span" sx={{ color: "#1CE7C2", fontSize: 28 }}>
              Frankendancer
            </Box>
            )
          </Box>

        </Typography>


        <Button
          component="a"
          href="https://github.com/firedancer-io/firedancer/tree/v0.1"
          target="_blank"
          variant="outlined"
          startIcon={<Github />}
          endIcon={<Arrow />}
          sx={{ mt: 1, mb: 2, px: 4, fontSize: 17 }}
        >
          Source on GitHub
        </Button>

      </Box>


      <Box
        component="div"
        sx={{
          display: "flex",
          alignItems: "center",
          background: "#091A3D",
          border: "1px solid #4B608D",
          p: 3.2,
          mb: 2.4,
          maxWidth: 1082,
          mx: "auto",
          textAlign: { xs: "center", md: "left" },
          flexDirection: { xs: "column", md: "row" },
          "& h2": {
            fontSize: { lg: 40 },
          },
        }}
      >
        <Box component="img" src={article} alt="Article Stack icon" />
        <Typography
          variant="h5"
          sx={{ flex: 1, px: 2.4, my: { xs: 2.4, md: 0 } }}
        >
          What is v0.1 (
          <Box component="span" sx={{ color: "#1CE7C2" }}>
            Frankendancer
          </Box>)?
        </Typography>
        <Button
          component="a"
          href="https://firedancer-io.github.io/firedancer/guide/firedancer.html#frankendancer"
          target="_blank"
          variant="outlined"
          endIcon={<Arrow />}
          sx={{ minWidth: "155px" }}
        >
          Read More
        </Button>
      </Box>


      <Box
        component="div"
        sx={{
          display: "flex",
          alignItems: "center",
          background: "#091A3D",
          border: "1px solid #4B608D",
          p: 3.2,
          mb: 2.4,
          maxWidth: 1082,
          mx: "auto",
          textAlign: { xs: "center", md: "left" },
          flexDirection: { xs: "column", md: "row" },
          "& h2": {
            fontSize: { lg: 40 },
          },
        }}
      >
        <Box component="img" src={article} alt="Article Stack icon" />
        <Typography
          variant="h5"
          sx={{ flex: 1, px: 2.4, my: { xs: 2.4, md: 0 } }}
        >
          Want to run a Frankendancer
          <Box component="span" sx={{ color: "#1CE7C2" }}>
            {" validator"}
          </Box>?

        </Typography>
        <Box
          component="div"
          sx={{
            display: "flex",
            alignItems: "right",
            gap: 1,
            flexDirection: "column"
          }}
        >

          <Button
            component="a"
            href="https://firedancer-io.github.io/firedancer/guide/faq.html"
            target="_blank"
            variant="outlined"
            endIcon={<Arrow />}
            sx={{ minWidth: "155px" }}
          >
            Frequently Asked Questions
          </Button>
          <Button
            component="a"
            href="https://firedancer-io.github.io/firedancer/guide/getting-started.html"
            target="_blank"
            variant="outlined"
            endIcon={<Arrow />}
            sx={{ minWidth: "155px" }}
          >
            Getting Started
          </Button>
          <Button
            component="a"
            href="https://github.com/firedancer-io/firedancer/releases"
            target="_blank"
            variant="outlined"
            endIcon={<Arrow />}
            sx={{ minWidth: "155px" }}
          >
            Release Notes
          </Button>
        </Box>

      </Box>


      <Box
        component="div"
        sx={{
          display: "flex",
          alignItems: "center",
          background: "#091A3D",
          border: "1px solid #4B608D",
          py: 3.2,
          px: { xs: 0.5, sm: 1, md: 2, lg: 3.2 },
          mb: 2.4,
          maxWidth: 1082,
          mx: "auto",
          textAlign: { xs: "center", md: "left" },
          flexDirection: "column",
          "& h2": {
            fontSize: { lg: 40 },
          },
        }}
      >
        <Box
          component="div"
          sx={{
            alignSelf: "start",
            justifySelf: "left",
            display: "flex",
            alignItems: "center",
            background: "#091A3D",
            mb: 2.4,
            width: "100%",
            textAlign: { xs: "center", md: "left" },
            flexDirection: { xs: "column", md: "row" },
            "& h2": {
              fontSize: { lg: 40 },
            },
          }}
        >
          <Box component="img" src={article} alt="Article Stack icon" />
          <Typography
            variant="h5"
            sx={{ flex: 1, px: 2.4, my: { xs: 2.4, md: 0 } }}
          >
            <Box component="span" sx={{ color: "#1CE7C2" }}>
              How it works
            </Box>
            , from Neodyme's Security Audit

          </Typography>
          <Button
            component="a"
            href="https://github.com/firedancer-io/audits/blob/main/Neodyme%20-%20Firedancer%20v0.1%20Audit.pdf"
            target="_blank"
            variant="outlined"
            endIcon={<Arrow />}
            sx={{ minWidth: "155px" }}
          >
            Read the full Secruity Audit
          </Button>
        </Box>


        <Box
          component="div"
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyItems: "center",
            p: { sm: 0, lg: 1.5 },
            mx: "auto",
          }}
        >
          <Box
            component="img"
            src={'/resources/Firedancer-v0.1-diagram.png'}
            sx={{ display: "block", width: "100%" }}
          />
        </Box>

      </Box>

    </Box>
  );
};

export default ReleaseZeroOne;
